<template>
    
  <div class="r-detail">
    <div class="breadcrumb w-1400">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/xiangninghui' }"
          >乡宁旺</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/recruit' }"
          >企业直聘</el-breadcrumb-item
        >
        <el-breadcrumb-item>我要应聘</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="rd-primary w-1400 row">
      <div>
        <div class="rd-state">招聘中</div>
        <div class="rd-price">
          {{ pageData.jobTitle }}
          <span class="fe620d">{{ pageData.salary }}</span>
        </div>
        <div class="rd-require">
          {{
            pageData.education == "不限" ? "学历不限" : pageData.education
          }}/{{
            pageData.experience == "不限" ? "经验不限" : pageData.experience
          }}/{{ pageData.jobMode }}
        </div>
        <div class="row rd-market">
          <!-- <div class="rd-market-list">感兴趣</div>
          <div class="rd-market-list">立即投递</div> -->
        </div>
      </div>
      <div>
        <div class="rd-name">{{ pageData.companyName }}</div>
        <div class="rd-export" v-if="pageData.welfare">
          <span v-for="item in pageData.welfare.split(',')" :key="item">{{
            item
          }}</span>
        </div>
        <div class="re-submit row">
          <!-- <div><i class="el-icon-edit"></i>填写在线简历</div>
          <div><i class="el-icon-upload"></i>上传简历附件</div> -->
        </div>
      </div>
    </div>
    <div class="rd-main w-1400">
      <el-row :gutter="30">
        <el-col :span="16">
          <div class="avatar row">
            <div class="avatar-box">
              <el-image
                style="width: 100%; height: 100%"
                :src="require('../../../assets/img/avatar1.png')"
              ></el-image>
            </div>
            <div class="a-info">
              <div>{{ pageData.contacts }}</div>
              <div>人力资源·本周活跃</div>
            </div>
          </div>
          <div class="zhiweimiaoshu">
            <div class="rd-h5"><span>职位描述</span></div>
            <div class="rd-inner" v-html="pageData.jobDescription">
              <p>岗位责任：</p>
              <p>
                1、建店服务：协同经营导师服务好每一位经销商，严格按标准施工流程要求督导建设好每一家
                店面，并协助经销商将施工人员打造成专业化，标准化，极致化的团队；<br />

                2、技术实操培训：严格按标准施工工艺流程教授每一位学员，并根据学员成绩给予等级验证；<br />

                3、体系建设/升级：协助产品经理测试新品，并及时客观反馈确测试结果，配合产品经理优化升
                级老品效果，不断更新与创造；<br />

                4、配合公司各部门制作样板。
              </p>
              <p>任职资格：</p>
              <p>
                1、中技以上学历，自配车；<br />

                2、涂料行业内部三年以上培训讲师经验；<br />

                3、熟悉产品工艺和产品知识；<br />
              </p>
            </div>
          </div>
          <div class="gongsijieshao">
            <div class="rd-h5"><span>公司介绍</span></div>
            <div class="rd-inner" v-html="pageData.companyIntroduction">
              西宁市城市餐饮管理有限公司是一家大型综合性餐饮机构。旗下涉及餐饮品牌运营、项目技术
              研发餐饮创业培训、餐饮门店经营、品牌项目推广、品牌策划于一体的综合型品牌机构。以“为
              中小型餐饮投资者保驾护航”为使命。秉承“承载信任、助力成功”的服务理念，坚持“自信、
              进取奉献、健康”的团队理念，始终如一的贯彻“自强不息、厚德载物、诚信为本”的经营理念。<br />
              <br />
              西宁市城市餐饮管理有限公司历经多年努力，逐步形成项目技术研发、品牌包装、营销策划、
              店铺直营、招商连锁、运营指导、培训指导为体系的行业全产业链整体服务系统；并以持续强化
              自主创新和品牌建设，深度挖掘餐饮产业市场巨大价值。通过创新型、标准化、便捷化、高盈利
              的餐饮项目，为创业伙伴创造财富、实现自我价值和创业梦想。<br />
              <br />
              西宁市城市餐饮管理有限公司作为中国餐饮项目合作行业的后起之秀，厚积薄发，开拓进取。
              着眼于中国餐饮行业所蕴藏的巨大商机和潜力，不断完善自己，不断开拓终端市场。增强自身研
              发实力，加强团队建设，尊重人才，追求实效。正以一个全新的姿态，迎接中国餐饮新变革!<br />
            </div>
          </div>
          <div class="gongzuodidian">
            <div class="rd-h5"><span>工作地点</span></div>
            <div class="rd-inner">
              <div class="gongzuodidian-pic">
                  <p>地址：{{ pageData.address }}</p>
                <!-- <baidu-map
                class="map"
                :center="{ lng: pageData.longitude, lat: pageData.latitude }"
                :scroll-wheel-zoom="true"
                :zoom="14"
              >
                <bm-marker
                  :position="{
                    lng: pageData.longitude,
                    lat: pageData.latitude,
                  }"
                  :dragging="true"
                >
                  <bm-label
                    :content="pageData.address"
                    :labelStyle="{
                      color: 'skyblue',
                      fontSize: '16px',
                      border: '1px solid skyblue',
                      transform: 'translateX(-48%)',
                    }"
                    :offset="{ width: 0, height: 30 }"
                  />
                </bm-marker>
              </baidu-map> -->
              </div>
            </div>
          </div>
          <div class="lianxifangshi">
            <div class="rd-h5"><span>联系方式</span></div>
            <div class="rd-inner">
              <p>电话：{{ pageData.telephone }}</p>
              <p>邮箱：{{ pageData.email }}</p>
            </div>
          </div>
          <div class="">
            <div class="rd-h5">看过该职位的人还看了</div>
            <el-row :gutter="15">
              <el-col :span="12" v-for="item in XnrecruitpageData" :key="item.id + 'card'">
                <router-link :to="`redirect?path=/recruitdetail&id=${item.id}`">
                  <div class="card bg-f">
                    <div class="ps1">
                      <span class="job">{{item.jobTitle}}</span>
                      <!-- <span class="time">[18:28发布]</span> -->
                      <span class="howpay">{{salary}}</span>
                    </div>
                    <div class="c-6">{{item.experience}}</div>
                    <div class="enterprise-info clearfix">
                      <div class="fl card-pic">
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="item.photoUrls"
                        ></el-image>
                      </div>
                      <div class="fr card-info">
                        <h5>{{item.companyName}}</h5>
                        <p>{{item.jobMode}}/招收{{item.recruitNumber}}人/{{item.regionName}}</p>
                      </div>
                    </div>
                  </div>
                </router-link>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="rd-main-r">
            <div class="r-h5">公司基本信息</div>
            <div class="r-name">
              <i
                :style="`background-image:url(${baseUrl + pageData.photoUrls})`"
              ></i
              >{{ pageData.companyName }}
            </div>
            <!-- <div class="r-list"><i class="el-icon-s-data"></i>已上市</div>
                        <div class="r-list"><i class="el-icon-user-solid"></i>100人以上</div>
                        <div class="r-list"><i class="el-icon-fork-spoon"></i>餐饮服务</div> -->

            <div class="r-list"><i class="el-icon-s-data"></i>{{pageData.welfare}}</div>
            <div class="r-list">
              <i class="el-icon-user-solid"></i>{{pageData.industry}}
            </div>


            <div class="r-list">相似职位</div>
            <div
              class="zp-item clearfix"
              v-for="item in XnrecruitpageData"
              :key="item.id + '22'"
            >
              <router-link :to="`/redirect?path=/recruitdetail&id=${item.id}`">
                <div class="fl zp-info">
                  <p>{{ item.jobTitle }}</p>
                  <p style="color: #fe620d">{{ item.salary}}</p>
                  <p>{{ item.industry }}</p>
                </div>
                <div class="fr zp-pic">
                  <el-image
                    :src="baseUrl + item.photoUrls"
                    style="width: 100%; height: 100%"
                    fit="cover"
                  ></el-image>
                </div>
              </router-link>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { GetOneXnrecruitInfos, GetXnrecruitInfos } from "@/api/xiangningwang";
export default {
  data() {
    return {
      pageData: {},
      XnrecruitpageData: {},
      pageParams: {
        currentPage: 1,
        pageSize: 6,
      },
    };
  },
  methods: {
    getXnrecruitPageList() {
      GetXnrecruitInfos(this.pageParams).then((res) => {
        this.XnrecruitpageData = res.data.data.list;
      });
    },
    getDetail() {
      GetOneXnrecruitInfos(this.$route.query.id ).then((res) => {
        this.pageData = res.data.data;
      });
    },
  },
  mounted() {
    this.getDetail();
    this.getXnrecruitPageList();
  },
  computed: {
    baseUrl: () => process.env.VUE_APP_PHOTO_URL,
    regHtml: () => regHtml,
  },
};
</script>
<style scoped>
/* .map {
  height: 500px;
} */
.r-list i {
  margin-right: 12px;
}
.r-list {
  line-height: 40px;
  color: #666;
}
.r-name i {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-image: url(../../../../public/img/xnw/enterprise-logo1.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;
  margin-right: 10px;
}
.r-name {
  line-height: 40px;
}
.r-h5 {
  line-height: 40px;
}
.zp-info {
  width: calc(100% - 145px);
  line-height: 30px;
  font-size: 17px;
}
.zp-pic {
  height: 90px;
  width: 122px;
}
.zp-item ~ .zp-item {
  margin-top: 20px;
}
.zp-item {
  padding: 10px;
  border-top: 1px solid #777;
  /* margin-top: 20px; */
  text-align: center;
}
.rd-main-r {
  padding: 15px;
  border: 1px solid #ddd;
  font-size: 16px;
}
.card-info {
  width: calc(100% - 95px);
  height: 80px;
  overflow: hidden;
  line-height: 40px;
  font-size: 16px;
}
.card-pic {
  height: 80px;
  width: 80px;
}
.enterprise-info {
  padding: 15px;
  margin-top: 15px;
  border-top: 2px solid #777;
}
.howpay {
  color: #f03442;
}
.c-6 {
  font-size: 16px;
  line-height: 30px;
}
.time,
.c-6 {
  color: #666;
}
.job {
  font-weight: 600;
}
.ps1 {
  height: 32px;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
.card {
  padding: 20px 10px;
  margin-bottom: 20px;
  background: #eee;
}
.gongzuodidian-pic {
   padding: 12px;
  width: 100%;
  height: 50px;
  overflow: hidden;
}
.rd-inner {
  padding-top: 10px;
  font-size: 15px;
  color: #666;
  line-height: 1.6;
}
.rd-h5 span {
  border-bottom: 2px solid #3aced8;
}
.rd-h5 {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 600;
}
.a-info > div:last-child {
  font-size: 18px;
  line-height: 40px;
}
.a-info > div:first-child {
  font-size: 20px;
  line-height: 50px;
}
.a-info {
  margin-left: 30px;
}
.avatar-box {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.rd-main {
  font-family: "微软雅黑";
  padding: 20px 40px;
}
.re-submit > div:hover {
  text-decoration: underline;
  cursor: pointer;
}
.re-submit > div {
  font-size: 20px;
  color: #fe620d;
  margin-right: 20px;
}
.re-submit {
  /* margin-top:60px; */
}
.rd-export > span {
  display: inline-block;
  color: #3aced8;
  border: 1px solid #3aced8;
  border-radius: 5px;
  font-size: 16px;
  padding: 5px 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.rd-export {
  /* padding: 0 10px; */
  height: 155px;
  overflow: hidden;
}
.rd-name {
  font-size: 28px;
  line-height: 80px;
}
.rd-market-list:hover {
  background: #3aced8;
  color: #fff;
  cursor: pointer;
}
.rd-market-list {
  padding: 10px 20px;
  margin-right: 20px;
  border: 1px solid #3aced8;
  color: #3aced8;
}
.rd-market {
  margin-top: 10px;
}
.rd-require {
  font-size: 16px;
  color: #e4e4e4;
  line-height: 40px;
}
.fe620d {
  color: #fe620d;
}
.rd-price {
  font-size: 30px;
  line-height: 100px;
}
.rd-state {
  font-size: 16px;
  line-height: 30px;
  color: #e4e4e4;
}
.rd-primary > div {
  width: 50%;
}
.rd-primary {
  height: 250px;
  /* width: 100%; */
  background: url(../../../assets/img/iwant-bg.png) no-repeat center center;
  padding: 10px 36px;
  color: #fff;
  font-weight: 600;
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>